import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import Languages from "../../languages.json";
import CustomSelect from "../common/CustomSelect";
import CustomInput from "../common/CustomInput";
import provisioningFormData from "../../constants/provisioningFormData";
import { useNavigate } from "react-router-dom";
import {
    addProvsioningForm,
    getProvisioningForms,
} from "../../api/accountProvisioning";
import TechseeLogo from "../../assets/TechseeLogo.png";
import DownloadIcon from "../../assets/download.png";

const AccountProvisioningForm = ({ user, config }) => {
    const [formData, setFormData] = useState(provisioningFormData);
    const [errorTooltip, setErrorTooltip] = useState("");
    const navigate = useNavigate();
    const csvLinkRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [selectedInstance, setSelectedInstance] = useState({});

    useEffect(() => {
        if (!user.firstName || !user?.connectInstances?.length > 0) {
            navigate("/admin");
        }
        if (config || user) {
            setLoading(true);
            getProvisioningForms(
                config?.provisioningApi || user?.provisioningApi
            )
                .then((data) => {
                    if (data && Object.keys(data).length > 0) {
                        setFormData((prevFromData) => ({
                            ...prevFromData,
                            ...data,
                            firstName: user?.firstName || "NA",
                            lastName: user?.lastName || "NA",
                            email: user?.email || "NA",
                            externalUserName: user?.externalUserName || "NA",
                        }));
                    } else {
                        const ID =
                            user?.connectInstances?.length > 0 &&
                            user?.connectInstances[0]?.value +
                                "-" +
                                user?.connectInstances[0]?.instanceAlias;
                        setFormData((prevFromData) => ({
                            ...prevFromData,
                            ID,
                            firstName: user?.firstName || "NA",
                            lastName: user?.lastName || "NA",
                            email: user?.email || "NA",
                            externalUserName: user?.externalUserName || "NA",
                        }));
                        setSelectedInstance(
                            user?.connectInstances?.length > 0
                                ? user?.connectInstances[0]
                                : {}
                        );
                    }
                })
                .catch((err) => {})
                .finally(() => setLoading(false));
        }
    }, [user, config]);

    const handleOnChange = (e) => {
        setFormData((pval) => ({
            ...pval,
            [e.target.name]: e.target.value,
        }));
    };

    const handleCirtificateChange = (e) => {
        let certificate = e.target.value;
        if (!certificate.includes('"') && e.target.value !== "") {
            certificate = `"${e.target.value}"`;
        }
        setFormData((pval) => ({
            ...pval,
            certificate,
        }));
    };

    const handleInstanceChange = (e) => {
        const ID = e.target.value;
        const instance = user?.connectInstances?.find(
            (ins) => ins.value === ID
        );
        const name = instance?.instanceAlias;
        setSelectedInstance(instance);
        setFormData((pval) => ({
            ...pval,
            ID: ID + "-" + name,
        }));
    };

    const isFromValid = () => {
        let result;
        const { loginUrl, logoutUrl, issuer, certificate } = formData;
        if (!loginUrl) {
            setErrorTooltip("Login Url can not be empty");
            result = false;
        } else if (!logoutUrl) {
            setErrorTooltip("Logout Url can not be empty");
            result = false;
        } else if (!issuer) {
            setErrorTooltip("Issuer can not be empty");
            result = false;
        } else if (!certificate) {
            setErrorTooltip("Certificate can not be empty");
            result = false;
        } else {
            setErrorTooltip("");
            result = true;
        }
        setTimeout(() => setErrorTooltip(""), 2000);
        return result;
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!isFromValid()) return;
        setLoading(true);

        const formDataData={...formData,connectInstances:selectedInstance?.instanceAlias} 
        const data = await addProvsioningForm(
            formDataData,
            config.provisioningApi || user.provisioningApi
        );
        if (data) {
            csvLinkRef.current.link.click();
            setFormData((pval) => ({ ...pval, [formData.ID]: formData }));
            navigate("/activation");
        } else {
            alert("error");
        }
        setLoading(false);
    };

    return (
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="bg-white shadow-xl flex-col text-formHeader p-6 rounded-2xl max-w-md w-full space-y-8">
                <img
                    className="mx-auto h-110 w-auto"
                    src={TechseeLogo}
                    alt=""
                />
                <h1 className="font-sans text-2xl font-bold ">
                    Account Provisioning
                </h1>
                <form onSubmit={handleOnSubmit} className="flex-col space-y-6 ">
                    <div className="rounded-md shadow-sm -space-y-px">
                        <CustomInput
                            value={formData.firstName}
                            onChange={handleOnChange}
                            name="firstName"
                            label="First Name"
                            type="text"
                            disabled
                        />
                        <CustomInput
                            value={formData.lastName}
                            onChange={handleOnChange}
                            name="lastName"
                            label="Last Name"
                            type="text"
                            disabled
                        />
                        <CustomInput
                            value={formData.email}
                            onChange={handleOnChange}
                            name="email"
                            label="Email"
                            type="text"
                            disabled
                        />
                        <CustomInput
                            value={formData.environmentType}
                            onChange={handleOnChange}
                            name="environmentType"
                            label="Environment Type"
                            type="text"
                            disabled
                        />
                        <CustomSelect
                            label="ID"
                            onChange={handleInstanceChange}
                            value={selectedInstance?.value || ""}
                            name="ID"
                            options={user.connectInstances}
                        />
                        <CustomSelect
                            label="Region"
                            value={formData.region}
                            onChange={handleOnChange}
                            name="region"
                            options={[
                                { name: "AWS Europe", value: "AWS Europe" },
                                {
                                    name: "AWS North America",
                                    value: "AWS North America",
                                },
                            ]}
                        />
                        <CustomSelect
                            label="Language"
                            value={formData.language}
                            onChange={handleOnChange}
                            name="language"
                            options={Languages}
                        />
                        <CustomInput
                            value={formData.loginUrl}
                            onChange={handleOnChange}
                            name="loginUrl"
                            label="Login Url"
                            type="url"
                        />
                        <CustomInput
                            value={formData.logoutUrl}
                            onChange={handleOnChange}
                            name="logoutUrl"
                            label="Logout Url"
                            type="url"
                        />
                        <CustomInput
                            value={formData.issuer}
                            onChange={handleOnChange}
                            name="issuer"
                            label="Issuer"
                            type="text"
                        />
                        <CustomInput
                            value={formData.certificate}
                            onChange={handleCirtificateChange}
                            name="certificate"
                            label="Certificate"
                            type="text"
                        />
                    </div>

                    <div>
                        <CSVLink
                            data={[formData]}
                            enclosingCharacter=""
                            filename="Techsee_Provisioning_Form"
                            target="_blank"
                            ref={csvLinkRef}
                        />
                        {errorTooltip && (
                            <div
                                className="bg-red-100  fixed top-2 right-2 rounded-lg py-7 px-6 mb-3 text-lg text-red-700 inline-flex items-center"
                                role="alert"
                            >
                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="times-circle"
                                    className="w-4 h-4 mr-2 fill-current"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                    ></path>
                                </svg>
                                {errorTooltip} !
                            </div>
                        )}
                        <button
                            type="submit"
                            className="shadow-xl m-auto font-semibold group relative w-96 flex justify-center py-2 px-4 border border-btnBlue text-sm rounded-3xl text-white bg-btnBlue
                            hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            <p className=" tracking-wide flex gap-1 justify-center items-center">
                                <img src={DownloadIcon} color="" alt="" />
                                {loading ? "Downloading..." : "Download"}
                            </p>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AccountProvisioningForm;
