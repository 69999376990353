import React from "react";

const CustomSelect = ({
    label,
    value,
    onChange,
    name,
    type = "text",
    options = [{ name: "", value: "" }],
    disabled = false,
}) => {
    return (
        <div>
            <label
                htmlFor={name}
                className={`block text-sm mt-3 mb-1 font-medium text-gray-${
                    disabled || options.length === 1 ? "300" : "500"
                }`}
            >
                {label}
            </label>
            <select
                value={value}
                onChange={onChange}
                name={name}
                type={type}
                className={`${
                    disabled && "cursor-not-allowed"
                } appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 ${
                    disabled ? "text-gray-300" : "text-gray-500"
                } focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                placeholder="Select"
                disabled={disabled || options.length === 1}
            >
                {options?.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default CustomSelect;
