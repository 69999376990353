import React from "react";
import TechseeLogo from "../assets/TechseeLogo.png";
const NavBar = () => {
    return (
        <nav className="text-white flex align-middle p-5 h-nav">
                <img src={TechseeLogo} alt="" className="w-189 h-10" />
        </nav>
    );
};

export default NavBar;
