const data = {
    ID: "", // show
    name: "Amazon Connect", // Reserved word & don't show
    edition: "Unlimited Edition",
    environmentType: "Sandbox organizations", // fetch from aws weather sandbox or production
    firstName: "", // show
    lastName: "", // show
    email: "", // show
    accountType: "regular", // reserved & don't show
    packageType: "AC", // reserved & don't show
    country: "NA", // don't show & if not available then use 'NA'
    defaultLocale: "NA", // don't show & if not available then use 'NA'
    defaultLanguage: "NA", // don't show & if not available then use 'NA'
    defaultTimeZone: "NA", // don't show & if not available then use 'NA'
    currencyLocale: "NA", // don't show & if not available then use 'NA'
    primaryContact: "NA", // don't show & if not available then use 'NA'
    division: "NA", // don't show & if not available then use 'NA'
    templateVersion: "1.0", //  don't show
    region: "AWS Europe", // user input dropdown [AWS North America, AWS Europe]
    language: "en_US", // user input dropdown value will be langCode
    loginUrl: "", //
    logoutUrl: "",
    issuer: "",
    certificate: "",
    reqTokenUrl: "NA",
    audience: "NA",
    externalUserName: "NA",
    useDomainZoneSuffix: true,
};

export const provisioningInputs = [];

export default data;
