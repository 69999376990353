import { useEffect } from "react";

const Admin = ({ config, user }) => {
    useEffect(() => {
        if (config || user) {
            window.open(config?.ssoApi || user?.ssoApi, "_self");
        }
    }, [config,user]);
    return (
        <div className="w-1/2 m-auto max-w-xs">
            <div className=" p-4 max-w-sm w-full mx-auto">
                <div className="animate-ping flex space-x-4">
                    <div className="rounded-full bg-gray-400 h-12 w-12"></div>
                    <div className="flex-1 space-y-4 py-1">
                        <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-400 rounded"></div>
                            <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                        </div>
                    </div>
                </div>
            </div>
            <p className="text-center mt-4 text-gray-500 text-xs">
                &copy;{new Date().getFullYear()} Techsse. All rights reserved.
            </p>
        </div>
    );
};

export default Admin;
