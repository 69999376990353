import axios from "axios";

export const addProvsioningForm = async (data, apiUrl) => {
    const res = await axios.post(apiUrl, data);
    if (res) return res;
};

export const deleteProvisioningForm = async (ID, apiUrl) => {
    const res = await axios.post(apiUrl, {
        ID,
        delete: true,
    });
    if (res) return res.data.body;
};

export const getProvisioningForms = async (apiUrl) => {
    try {
        const res = await axios.get(apiUrl);
        if (res) return res.data;
    } catch (error) {
        return {};
    }
};

// activation
export const addActivationData = async (data, apiUrl) => {
    const res = await axios.post(apiUrl, data);
    if (res) return res.data;
};

export const getActivationData = async (apiUrl) => {
    const res = await axios.get(apiUrl);
    if (res) return res.data;
};
