import React, { memo, useEffect, useRef } from "react";
import "amazon-connect-streams";

// functional component for aws connect instance.
const Ccp = ({ selectedInstance, onChildData }) => {
  const ref = useRef();
  useEffect(() => {
    try {
      if (typeof window === "undefined") throw new Error("window missing");
      if (typeof window.connect === "undefined")
        throw new Error("global connect missing");
      window.connect.core.initCCP(ref.current, {
        // dynamic connect instance url
        ccpUrl: `https://${selectedInstance}.my.connect.aws/connect/ccp-v2`,
        loginPopup: true,
        loginPopupAutoClose: true,
        loginOptions: {
          autoClose: true,
          height: 600,
          width: 400,
          top: 250,
          left: 800,
        },
        pageOptions: {
          enableAudioDeviceSettings: true,
          enablePhoneTypeSettings: true,
        },
        softphone: { allowFramedSoftphone: true },
        ccpAckTimeout: 5000,
        ccpSynTimeout: 3000,
        ccpLoadTimeout: 10000,
      });
      //for aws connect popup will auto close when it reflect into the connect iframe.
      window.localStorage.removeItem(
        "connectPopupManager::connect::loginPopup"
      );
      //get contact number and contact id from the connect instance.
      function getContact() {
        window.connect.contact(function (contact) {
          // when the call connected via call or chat the function will run.
          contact.onConnected(function (contact) {
            let data = null;
            const str = contact.getContactId();
            const index = str.lastIndexOf("-");
            const customer_reference_number = str.substring(index + 1, str.length);
            // when we get the call via chat.
            if (contact.getType() === "chat") {
              data = {
                customer_reference_number: customer_reference_number,
              };
              onChildData(data);
            // when we get call via skype etc 
            } else if (contact.getType() === "voice") {
              const phoneNumber = contact
                .getActiveInitialConnection()
                .getEndpoint().phoneNumber;
              data = {
                phoneNumber: phoneNumber,
                customer_reference_number: customer_reference_number,
              };
              onChildData(data);
            }
          });
        });
      }
      getContact();
    } catch (e) {
    }
  }, [ref]);
  return (
    <div
      ref={ref}
      style={{
        width: "100%",
        height: "500px",
      }}
    />
  );
};

export default memo(Ccp);
