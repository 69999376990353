import React from "react";

const CustomInput = ({
    name,
    value,
    onChange,
    type = "text",
    disabled = false,
    label,
    required = false,
    accept
}) => {
    return (
        <div>
            <label
                htmlFor={name}
                className={`block text-sm mt-3 mb-1 font-medium ${disabled ? "text-gray-300":"text-black"}`}
            >
                {label}
            </label>
            <input
                value={value}
                disabled={disabled}
                onChange={onChange}
                name={name}
                type={type}
                className={`${
                    disabled && "cursor-not-allowed"
                } appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 ${disabled ?"text-gray-300":"text-black"}
                } mb-1 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                accept={accept}
            />
        </div>
    );
};

export default CustomInput;
