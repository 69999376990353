export const decodeSearch = (search) => {
    let user = {};
    let connectInstances = [];
    if (search) {
        const [userData, instancesData] = decodeURI(search).split("&");
        if (userData && userData.includes("="))
            user = JSON.parse(userData.split("=")[1]);
        if (instancesData && instancesData.includes("="))
            connectInstances = JSON.parse(instancesData.split("=")[1]);
    }

    return [user, connectInstances];
};
