import React, { useEffect, useState } from "react";
import CustomInput from "../common/CustomInput";
import { useNavigate } from "react-router-dom";
import { addActivationData } from "../../api/accountProvisioning";
import TechseeLogo from "../../assets/TechseeLogo.png";

const AccountActivationForm = ({ user, config }) => {
    const [formData, setFormData] = useState({
        accountId: "",
        apiKey: "",
        apiSecret: "",
        accountSubdomain: "",
        apiUrl: "",
        ssoUrl: "",
        acsUrl: "",
        accountLanguage: "",
    });
    const [errorTooltip, setErrorTooltip] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (!user.firstName || !user?.connectInstances?.length > 0) {
            navigate("/admin");
        }
    }, [user, navigate]);

    const handleOnChange = (e) => {
        setFormData((pval) => ({
            ...pval,
            [e.target.name]: e.target.value,
        }));
    };

    const handleBackClick = (e) => {
        e.preventDefault();
        navigate("/provisioning");
    };

    const isFromValid = () => {
        let result;
        const {
            accountId,
            accountSubdomain,
            apiUrl,
            ssoUrl,
            acsUrl,
            accountLanguage,
        } = formData;
        if (!accountId) {
            setErrorTooltip("Account ID can not be empty");
            result = false;
        } else if (!accountSubdomain) {
            setErrorTooltip("Account Subdomain Url can not be empty");
            result = false;
        } else if (!apiUrl) {
            setErrorTooltip("API Url can not be empty");
            result = false;
        } else if (!ssoUrl) {
            setErrorTooltip("SSO Url can not be empty");
            result = false;
        } else if (!acsUrl) {
            setErrorTooltip("ACS Url can not be empty");
            result = false;
        } else if (!accountLanguage) {
            setErrorTooltip("Account Language can not be empty");
            result = false;
        } else {
            setErrorTooltip("");
            result = true;
        }
        setTimeout(() => setErrorTooltip(""), 2000);
        return result;
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!isFromValid()) return;
        setLoading(true);
        try {
            const data = await addActivationData(
                formData,
                config.activationApi || user.activationApi
            );
            if (data) navigate("/agent");
        } catch (error) {}
        setLoading(false);
    };

    return (
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="bg-white shadow-xl text-formHeader p-6 rounded-2xl max-w-md w-full space-y-8">
                <div>
                    <img
                        className="mx-auto h-110 w-auto"
                        src={TechseeLogo}
                        alt=""
                    />
                </div>
                <h1 className="font-sans text-2xl font-bold ">
                    Account Activation
                </h1>
                <form onSubmit={handleOnSubmit} className="flex-col m-auto space-y-6">
                    <div className="rounded-md shadow-sm -space-y-px">
                        <CustomInput
                            value={formData.accountId}
                            onChange={handleOnChange}
                            name="accountId"
                            label="Account Id"
                            type="text"
                        />
                        <CustomInput
                            value={formData.accountSubdomain}
                            onChange={handleOnChange}
                            name="accountSubdomain"
                            label="Account Subdomain"
                            type="text"
                        />
                        <CustomInput
                            value={formData.apiUrl}
                            onChange={handleOnChange}
                            name="apiUrl"
                            label="API Url"
                            type="url"
                        />
                        <CustomInput
                            value={formData.ssoUrl}
                            onChange={handleOnChange}
                            name="ssoUrl"
                            label="SSO Url"
                            type="url"
                        />
                        <CustomInput
                            value={formData.acsUrl}
                            onChange={handleOnChange}
                            name="acsUrl"
                            label="ACS Url"
                            type="url"
                        />
                        <CustomInput
                            value={formData.accountLanguage}
                            onChange={handleOnChange}
                            name="accountLanguage"
                            label="Account Language"
                            type="text"
                        />
                    </div>

                    <div className="flex justify-around ">
                        {errorTooltip && (
                            <div
                                className="bg-red-100  fixed top-2 right-2 rounded-lg py-7 px-6 mb-3 text-lg text-red-700 inline-flex items-center"
                                role="alert"
                            >
                                <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="times-circle"
                                    className="w-4 h-4 mr-2 fill-current"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                    ></path>
                                </svg>
                                {errorTooltip} !
                            </div>
                        )}
                        <button
                            onClick={handleBackClick}
                            filename="Techsee_Provisioning_Form"
                            className="shadow-xl font-semibold group relative w-48 flex justify-center py-2 px-4 border border-btnBlue text-sm rounded-3xl text-btnBlue bg-white hover:bg-btnBlue
                            hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            <p className="tracking-wide flex justify-center items-center">
                                Back
                            </p>
                        </button>
                        <button
                            type="submit"
                            filename="Techsee_Provisioning_Form"
                            className="shadow-xl group relative w-48 flex justify-center py-2 px-4 border border-btnBlue text-sm font-semibold rounded-3xl text-btnBlue bg-white hover:bg-btnBlue
                            hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            <p className="tracking-wide flex justify-center items-center">
                                {loading ? "Loading..." : "Activate"}
                            </p>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AccountActivationForm;
