import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Agent from "./components/agent/Agent";
import AccountActivationForm from "./components/admin/AccountActivationForm";
import AccountProvisioningForm from "./components/admin/AccountProvisioningForm";
import Admin from "./components/admin/Admin";
import { decodeSearch } from "./util";
import { useEffect, useState } from "react";

function App() {
    const [user, setUser] = useState({});
    const [config, setConfig] = useState();
    const { search } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {

        async function fetchConfig() {
            try {
              const response = await fetch('./techsee.json');
              
              if (response.ok) {
                const data = await response.json();

                  if(data){
                    const responseconfig = await fetch(data.api);
                    const dataconfig = await responseconfig.json();
                    setConfig(dataconfig);
                  }else{
                    fetch("./config.json").then((response) =>
                    response.json().then((config) => setConfig(config))
                );
                  }
                
              } else {
                fetch("./config.json").then((response) =>
                response.json().then((config) => setConfig(config))
            );
              }
            } catch (error) {
                fetch("./config.json").then((response) =>
                response.json().then((config) => setConfig(config))
            );
            }
          }
          fetchConfig();


       
    }, []);
    useEffect(() => {
        if (search) {
            const [user, connectInstances] = decodeSearch(search);
            setUser({ ...user, connectInstances });
        }
    }, [search]);

    useEffect(() => {
        if (Object.keys(user).length > 0) {
            navigate("provisioning");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
    return (
        <div className="min-h-screen flex flex-col">
            <Routes>
                <Route path="/" element={<Agent user={user} config={config} />} />
                <Route
                    path="/agent"
                    element={<Agent user={user} config={config} />}
                />
                <Route
                    path="admin"
                    element={<Admin user={user} config={config} />}
                />
                <Route
                    path="activation"
                    element={
                        <AccountActivationForm user={user} config={config} />
                    }
                />
                <Route
                    path="provisioning"
                    element={
                        <AccountProvisioningForm user={user} config={config} />
                    }
                />
            </Routes>
        </div>
    );
}

export default App;
