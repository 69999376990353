import React, { useCallback, useEffect, useState, useRef } from "react";
import Ccp from "./Ccp";
import VidButton from "../../assets/Vector.png";
import CenterImage from "../../assets/centerImage.png";
import Modal from "react-modal";
import "./Agent.css";
import NavBar from "../NavBar";
import { ClipLoader } from "react-spinners";
import { phone } from "phone";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const Agent = ({ config, user }) => {
  // variable subtitle for modal css and mywindow for the techsee popup.
  let subtitle, mywindow;
  const [showTechsee, setShowTechsee] = useState(false);
  const [techseeURL, setTechseeURL] = useState(undefined);
  
  // useState for the popup window of techsee. 
  const [mwindow, setmwindow] = useState(false);
  const [windoww, setwindow] = useState();

  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  
  // useState for the techsee popup auto close to particular event of techsee site (authId). 
  const [authId, setAuthId] = React.useState();
  const [key, setkey] = React.useState(0);

  const stateRef = useRef();
  const screenRef = useRef(null);
  stateRef.current = authId;

  const CCP = useCallback(
    ({ selectedInstance, onChildData }) => (
      <Ccp selectedInstance={selectedInstance} onChildData={onChildData} />
    ),
    []
  );
  useEffect(() => {
    setTechseeURL(config?.ssoUrl);
  }, [config]);

  useEffect(() => {
    fetchData();
  }, []);

  const callback = () => {
    if (stateRef.current) {
    } else {
      setkey((key) => key + 1);
      setTimeout(callback, 10000);
    }
  };
  function fetchData() {
    setLoading(true);
    if (authId) {
      setLoading(false);
    }
  }
  // for the modal to open or close & cancel the techsee session.
  function openModal() {
    if (showTechsee === false) {
      setShowTechsee(true);
    } else {
      setIsOpen(true);
    }
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    showTechsee(false);
    techseeURL(config?.ssoUrl);
  }

  function cancelModal(event) {
    event.preventDefault();
    setIsOpen(false);
    setShowTechsee(true);
  }

  // for handle the techsee popup 
  function techseeFrame() {
    mywindow = window.open(techseeURL, "techseePopUp", 500, 400);
    callback();
    window.addEventListener("message", function (event) {
      if (event.data && event.data.event === "TechSeeAuthStatus") {
        // handle the TechSeeAuthStatus event here
        var value = event.data.value;
        var authId = event.data.authId;
        setAuthId(authId);
        mywindow.close();
        if (value === "ok") {
        } else {
        }
      }
    });
    setwindow(mywindow);
  }

  function closetechseewindow() {
    windoww.close();
    setmwindow(false);
  }

  // for handle the techsee iframe into the fullscreen.
  function openFullscreen() {
    const elem = screenRef.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  }

  /* get data from ccp component to handle to pass the contact number, country code & customer reference number as a
     query string in the techsee url so we get these paremeters into the techsee input box either in call and chat */
    const handleChildData = (data) => {
    const code = phone(data.phoneNumber, { country: "" });
    if (data.customer_reference_number && data.phoneNumber) {
      const ccode = code.countryCode;
      let onlyPh = code.phoneNumber.replace(ccode, "");
      const SSOURL = config.ssoUrl;
      setTechseeURL(SSOURL);
      const params = `?r=${data.customer_reference_number}&p=${onlyPh}&c=${ccode}`;
      const urlwithparams = SSOURL.concat(params);
      setTechseeURL(urlwithparams);
    } else if (data.customer_reference_number != null) {
      const SSOURL = config.ssoUrl;
      const params = `?r=${data.customer_reference_number}`;
      const urlwithparams = SSOURL.concat(params);
      setTechseeURL(urlwithparams);
    } else {
      setTechseeURL(config.ssoUrl);
    }
  };
  const tooltip = <Tooltip id="button-tooltip">Expand</Tooltip>;
  return (
    <>
      {config ? (
        <>
          <NavBar />
          <div className="inline-flex items-start w-100 flex-wrap justify-between p-6 ">
            <div className="inline-flex flex-wrap w-330 bg-white shadow-md  rounded-xl overflow-hidden">
              <CCP
                selectedInstance={config?.connectInstances}
                onChildData={handleChildData}
              />
              <div className="p-4 inline-flex w-full justify-center">
                <button
                  onClick={function () {
                    openModal();
                    if (!mwindow) {
                      techseeFrame();
                      setmwindow(true);
                    }
                  }}
                  className={`inline-flex justify-center items-center p-2 rounded-button w-full gap-2 ${
                    showTechsee ? "bg-techseeRed" : "bg-techseeBlue"
                  } shadow-2xl`}
                >
                  <img src={VidButton} className="w-5" alt="" />
                  <h1 className="text-white">
                    {showTechsee ? "Close" : "Launch"} TS Live Session
                  </h1>
                </button>
                <Modal
                  isOpen={modalIsOpen}
                  onAfterOpen={afterOpenModal}
                  onRequestClose={closeModal}
                  style={customStyles}
                  contentLabel="Modal"
                >
                  <div className="flex justify-between items-center  mb-6">
                    <h2>Close TechSee Video Session?</h2>
                    <button onClick={cancelModal}>
                      <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                          fill="#4B5563"
                        />
                      </svg>
                    </button>
                  </div>
                  <div>
                    {" "}
                    This action will take you to homepage and cannot undo{" "}
                  </div>

                  <form>
                    <button
                      onClick={cancelModal}
                      className="font-semibold popup-cancel-btn"
                    >
                      CANCEL
                    </button>
                    <button
                      onClick={function () {
                        closetechseewindow();
                      }}
                      ref={(_subtitle) => (subtitle = _subtitle)}
                      className="font-semibold"
                    >
                      CLOSE
                    </button>
                  </form>
                </Modal>
              </div>
            </div>
            <div className="inline-flex- justify-center items-center w-c-width h-c-height pl-6 overflow-hidden ">
              {showTechsee ? (
                <>
                  {!authId && techseeURL && (
                    <>
                      <iframe
                        ref={screenRef}
                        src={techseeURL}
                        title="ss"
                        key={key.toString() || "key1"}
                        style={{ visibility: "hidden" }}
                      />
                    </>
                  )}

                  {techseeURL && authId ? (
                    <iframe
                      ref={screenRef}
                      src={techseeURL}
                      title="expand"
                      allow="camera; microphone; geolocation; display-capture;  allow-popups; allow-forms; allow-scripts; allow-same-origin; fullscreen"
                      allowFullScreen
                      className="w-full h-full rounded-xl overflow-hidden bg-white flex flex-col flex-wrap justify-center items-center shadow-md"
                    ></iframe>
                  ) : (
                    <div className="loader-center">
                      <ClipLoader
                        size={70}
                        color={"#123abc"}
                        loading={loading}
                      />
                    </div>
                  )}
                  <div>
                    <OverlayTrigger placement="top" overlay={tooltip}>
                      <div className="btn" onClick={openFullscreen}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          fill="#1e99fd"
                          className="bi bi-fullscreen"
                          viewBox="0 0 16 16"
                        >
                          <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
                        </svg>
                      </div>
                    </OverlayTrigger>
                  </div>
                </>
              ) : (
                <div className="w-full h-full rounded-xl overflow-hidden bg-white flex flex-col flex-wrap justify-center items-center shadow-md">
                  <img className="" src={CenterImage} alt="" />
                  <div className="w-full flex flex-col justify-center items-center p-10">
                    <h1 className="text-4xl">TechSee Visual Assistance</h1>
                    <p className="p-1 text-xl">
                      A convenient and efficient way to troubleshoot issues and
                      solve problems,
                      <span className="text-techseeBlue cursor-pointer">
                        {" "}
                        <button
                          onClick={function () {
                            openModal();
                            if (!mwindow) {
                              techseeFrame();
                              setmwindow(true);
                            }
                          }}
                        >
                          <h1>Invite Live Session</h1>
                        </button>
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>loading</>
      )}
    </>
  );
};

export default Agent;
